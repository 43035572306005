<template>
  <v-container>
    <v-app-bar dark app color="primary" clipped-right>
      <v-app-bar-nav-icon>
        <v-icon>mdi-account-cash</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Personenkonti</v-toolbar-title>

      <v-spacer />
      <PersonInput
        group="person"
        @input="
          (ev) =>
            ev ? (selectSchoolClass(ev.schoolClass), showDetails(ev.id)) : ''
        "
        label="Person wählen"
        hide-details
        single-line
        clearable
      />
    </v-app-bar>
    <v-row>
      <v-col>
        <v-card v-if="schoolClass">
          <v-list-item v-if="schoolClass.id">
            <v-list-item-content>
              <v-row>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5">
                        {{ schoolClass.code }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ schoolClass.studentCount.total }} Schüler:innen
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <PersonItem
                    :value="schoolClass.teacher"
                    clickable
                    description="Klassenlehrperson"
                  />
                  <PersonItem
                    v-if="schoolClass.substituteTeacher"
                    :value="schoolClass.substituteTeacher"
                    clickable
                    description="Stv. Klassenlehrperson"
                  />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <v-row dense>
              <v-col>
                <v-dialog v-model="newDialog" width="500" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="selectedStudents.length < 1"
                      depressed
                      small
                      block
                      color="success"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>

                  <v-card>
                    <v-system-bar window>Transaktion hinzufügen </v-system-bar>
                    <v-card-text class="mt-4 pb-2">
                      <v-chip-group show-arrows column>
                        <PersonChip
                          v-for="person in selectedStudents"
                          :value="person"
                          :key="'chip' + person.id"
                        />
                      </v-chip-group>
                      <DateInput label="Datum" v-model="newItem.date" />
                      <LookupValueInput
                        label="Buchungsart"
                        v-model="newItem.type"
                        resource="finance/transactiontype"
                      ></LookupValueInput>
                      <v-text-field label="Anlass" v-model="newItem.event" />
                      <v-text-field
                        label="Beschreibung"
                        v-model="newItem.description"
                      />
                      <v-text-field
                        label="Rechnungsnummer"
                        v-model="newItem.invoiceNumber"
                        counter="20"
                      />
                      <v-text-field
                        type="number"
                        label="Betrag"
                        v-model="newItem.amount"
                      />
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-spacer />
                      <v-btn text @click="newDialog = false">abbrechen</v-btn
                      ><v-btn
                        text
                        color="primary"
                        :disabled="!saveable"
                        :loading="saving"
                        @click="saveNew"
                        >speichern</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="auto">
                <v-btn block depressed small color="red" @click="selectRed"
                  >auswählen</v-btn
                >
              </v-col>
              <v-col cols="auto">
                <v-dialog v-model="invoiceDialog" width="800" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      block
                      depressed
                      small
                      color="primary"
                      :disabled="selectedStudents.length < 1"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon left>mdi-credit-card</v-icon> Rechnung</v-btn
                    >
                  </template>

                  <v-card>
                    <v-system-bar window
                      >Rechnung stellen {{ invoiceStep }}</v-system-bar
                    >

                    <v-card-text class="mt-4 pb-2">
                      <v-stepper flat v-model="invoiceStep" vertical>
                        <v-stepper-step :complete="invoiceStep > 1" step="1">
                          Schüler:innen auswählen
                          <small
                            >{{ selectedStudents.length }} Schüler:innen ({{
                              invoiceClassCodes
                            }})</small
                          >
                        </v-stepper-step>

                        <v-stepper-content step="1">
                          <v-chip-group show-arrows column>
                            <PersonChip
                              v-for="person in selectedStudents"
                              :value="person"
                              :key="'chip' + person.id"
                            />
                          </v-chip-group>

                          <v-btn @click="invoiceDialog = false" class="mr-2"
                            >abbrechen</v-btn
                          >
                          <v-btn color="primary" @click="invoiceStep = 2">
                            weiter
                          </v-btn>
                        </v-stepper-content>

                        <v-stepper-step :complete="invoiceStep > 2" step="2">
                          Anlassnummer setzen
                          <small>{{ invoiceEventID }}</small>
                        </v-stepper-step>

                        <v-stepper-content step="2">
                          <v-text-field
                            label="IDAnlass"
                            v-model="invoiceEventID"
                          />
                          <v-btn @click="invoiceStep = 1" class="mr-2">
                            zurück
                          </v-btn>
                          <v-btn
                            color="primary"
                            :disabled="!invoiceEventID"
                            @click="invoiceStep = 3"
                          >
                            weiter
                          </v-btn>
                        </v-stepper-content>

                        <v-stepper-step :complete="invoiceStep > 3" step="3">
                          Daten nach Evento exportieren
                          <small v-if="invoiceCopied">kopiert!</small>
                        </v-stepper-step>

                        <v-stepper-content step="3">
                          <v-textarea
                            outlined
                            class="code"
                            readonly
                            :value="invoiceTable"
                          ></v-textarea>

                          <v-btn @click="invoiceStep = 2" class="mr-2">
                            zurück
                          </v-btn>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="copyEventoData"
                                color="info"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                              >
                                <v-icon left>mdi-export</v-icon> in
                                Zwischenablage
                              </v-btn>
                            </template>
                            Daten für Export nach Evento in Zwischenablage
                            kopieren
                          </v-tooltip>
                          <v-btn
                            color="primary"
                            @click="step4()"
                            :disabled="!invoiceCopied"
                          >
                            weiter
                          </v-btn>
                        </v-stepper-content>

                        <v-stepper-step :complete="invoiceStep > 4" step="4">
                          Buchungen vornehmen
                          <small>{{ selectedItems.length }} Buchungen</small>
                        </v-stepper-step>

                        <v-stepper-content step="4">
                          <v-chip-group show-arrows column>
                            <PersonChip
                              v-for="person in selectedStudents"
                              :value="person"
                              :key="'chip' + person.id"
                            />
                          </v-chip-group>

                          <v-btn
                            @click="invoiceStep = 3"
                            class="mr-2"
                            :disabled="selectedItems.length == 0"
                          >
                            zurück
                          </v-btn>
                          <v-btn
                            color="info"
                            @click="invoiceBook"
                            class="mr-2"
                            :disabled="selectedItems.length == 0"
                            :loading="saving"
                          >
                            Buchungen starten
                          </v-btn>
                          <v-btn
                            color="primary"
                            :disabled="selectedItems.length > 0"
                            @click="invoiceDialog = false"
                          >
                            schliessen
                          </v-btn>
                        </v-stepper-content>
                      </v-stepper>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="invoiceDialog = false"
                        >abbrechen</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <ReportButton
                      v-bind="attrs"
                      v-on="on"
                      small
                      outlined
                      text
                      resource="report/personbalance"
                      color="success"
                      :parameters="
                        schoolClass.id
                          ? { id: schoolClass.id, type: 'class' }
                          : { type: 'teacher' }
                      "
                      >XLS</ReportButton
                    >
                  </template>
                  <span>Personenkonti</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />

          <v-data-table
            v-model="selectedItems"
            show-select
            item-key="person.id"
            :loading="loadingOverview"
            :headers="headers"
            :items="items"
            disable-pagination
            :items-per-page="-1"
            sort-by="person"
            :item-class="
              (item) =>
                item.balance < -199.99 ? 'red lighten-4 clickable' : 'clickable'
            "
            @click:row="(item) => showDetails(item.person.id)"
          >
            <template v-slot:item.person="{ item }">
              <PersonItem small :value="item.person" />
            </template>
            <template v-slot:item.balanceCantonal="{ item }">
              <CurrencyValue :value="item.balanceCantonal" />
            </template>
            <template v-slot:item.balanceOwn="{ item }">
              <CurrencyValue :value="item.balanceOwn" />
            </template>
          </v-data-table>
          <v-divider />
          <v-card-actions>
            <CsvCopy
              :headers="headers"
              :items="items"
              title="Personenkonti"
              :subtitle="schoolClass"
              >CSV</CsvCopy
            >
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col class="col-auto">
        <v-navigation-drawer app clipped permanent right>
          <v-list nav dense>
            <v-list-item>
              <SearchField
                label="Suche nach Gruppen"
                v-model="search"
              ></SearchField
            ></v-list-item>

            <v-list-item class="px-4" @click="selectTeachers()">
              <v-list-item-content>
                <v-list-item-title>LK</v-list-item-title>
                <v-list-item-subtitle>alle Lehrkräfte</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <SchoolClass
              v-for="item in filteredSchoolClasses"
              :key="item.id"
              nodetails
              nolink
              clickable
              @click="selectSchoolClass(item)"
              :value="item"
            />
            <v-list-item v-if="!loading && filteredSchoolClasses.length == 0"
              >keine Suchtreffer</v-list-item
            >
            <v-skeleton-loader
              v-if="loading"
              type="list-item-two-line"
            ></v-skeleton-loader>
          </v-list>
        </v-navigation-drawer>
      </v-col>
    </v-row>
    <router-view @dataUpdated="fetchData"></router-view>
  </v-container>
</template>
<script>
import { defineComponent } from "vue";
import DateInput from "common/components/DateInput.vue";
import CsvCopy from "common/components/CsvCopy.vue";
import CurrencyValue from "common/components/CurrencyValue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItem from "common/components/PersonItem";
import PersonChip from "common/components/PersonChip.vue";
import PersonInput from "common/components/PersonInput.vue";
import ReportButton from "@/components/ReportButton";
import SchoolClass from "@/components/SchoolClass";
import SearchField from "@/components/SearchField";

import { comparePeople } from "common/utils/people.js";
import { today } from "common/utils/date.js";

export default defineComponent({
  components: {
    DateInput,
    CsvCopy,
    CurrencyValue,
    LookupValueInput,
    PersonItem,
    PersonChip,
    PersonInput,
    ReportButton,
    SchoolClass,
    SearchField,
  },
  data() {
    return {
      loading: false,
      loadingOverview: false,
      newDialog: false,
      invoiceDialog: false,
      invoiceEventID: null,
      invoiceCopied: false,
      invoiceStep: 1,
      newItem: {},
      saving: false,
      selectedItems: [],
      schoolClass: {},
      schoolClasses: [],
      items: [],
      search: "",
      headers: [
        { text: "Person", value: "person", sort: comparePeople },
        {
          text: "Beitrag Kanton",
          value: "balanceCantonal",
          align: "end",
        },
        {
          text: "Eigene Kosten",
          value: "balanceOwn",
          align: "end",
        },
      ],
    };
  },
  computed: {
    invoiceClassCodes() {
      return [
        ...new Set(this.selectedItems.map((el) => el.person.schoolClass.code)),
      ].join(", ");
    },
    invoiceTable() {
      if (!this.invoiceDialog || !this.invoiceEventID) {
        return "";
      }
      let result = "";
      for (const item of this.selectedItems) {
        if (item.balance < 0) {
          result += this.invoiceEventID;
          result += "\t";
          result += item.person.eventoId;
          result += "\t";
          result += "100";
          result += "\t";
          result += -item.balance.toFixed(2);
          result += "\n";
        }
      }
      return result;
    },
    saveable() {
      return (
        this.newItem.amount &&
        this.newItem.date &&
        this.newItem.event &&
        this.newItem.description &&
        this.newItem.invoiceNumber &&
        this.newItem.type &&
        this.selectedStudents.length > 0
      );
    },
    selectedStudents() {
      return this.selectedItems.map((el) => el.person);
    },
    filteredSchoolClasses() {
      if (!this.search) {
        return this.schoolClasses;
      }
      return this.schoolClasses.filter(
        (item) =>
          item.code.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.majorSubjects &&
            item.majorSubjects.includes(this.search.toUpperCase())) ||
          (item.classTeacher &&
            item.classTeacher.code === this.search.toLowerCase()) ||
          (item.assistantClassTeachers &&
            item.assistantClassTeachers.some(
              (teacher) => teacher.code === this.search.toLowerCase()
            ))
      );
    },
  },
  watch: {
    newDialog() {
      this.newItem = {
        date: today(),
        description: "",
        amount: "",
        type: { id: 1 },
      };
    },
    invoiceDialog() {
      this.invoiceCopied = false;
      this.invoiceStep = 1;
    },
    invoiceEventID() {
      this.invoiceCopied = false;
    },
    async schoolClass(oldValue, newValue) {
      if (oldValue != newValue) {
        this.fetchData();
      }
    },
  },
  methods: {
    async copyEventoData() {
      try {
        await navigator.clipboard.writeText(this.invoiceTable);
        this.$root.showSuccess(
          "<strong>in Zwischenablage kopiert:</strong><br>" + this.invoiceTable
        );
        this.invoiceCopied = true;
      } catch (err) {
        this.$root.showError("Fehler beim Kopieren in die Zwischenablage");
      }
    },
    async fetchData() {
      this.loadingOverview = true;
      if (this.schoolClass.id) {
        this.items = await this.apiList({
          resource: "finance/personoverview",
          query: `schoolClass=${this.schoolClass.id}`,
        });
        localStorage.setItem("financeSchoolClass", this.schoolClass.id);
        if (this.$route.query.schoolClass != this.schoolClass.id) {
          this.$router.push({
            query: { schoolClass: this.schoolClass.id },
          });
        }
      } else {
        this.items = await this.apiList({
          resource: "finance/personoverview",
          query: `teachers`,
        });
        localStorage.setItem("financeSchoolClass", "teachers");
        if (this.$route.query.teachers != "true") {
          this.$router.push({
            query: { teachers: true },
          });
        }
      }

      this.loadingOverview = false;
    },
    async saveNew() {
      this.saving = true;
      const selectedIds = this.selectedStudents.map((el) => el.id);
      for await (const studentId of selectedIds) {
        const data = {
          ...this.newItem,
          person: studentId,
        };
        await this.apiPost({
          resource: "finance/persontransaction",
          data: data,
          onError: (message) => {
            this.$root.showError(`Fehler: <strong>${message}</strong>`);
          },
          onSuccess: () => {
            const index = this.selectedItems.findIndex(
              (el) => el.person.id == studentId
            );
            this.selectedItems.splice(index, 1);
          },
        });
      }
      setTimeout(() => {
        this.fetchData();
        this.saving = false;
        if (this.selectedItems.length == 0) {
          this.newDialog = false;
        }
      }, 1200);
    },
    async invoiceBook() {
      this.saving = true;
      const selectedIds = this.selectedStudents.map((el) => el.id);
      for await (const studentId of selectedIds) {
        const item = this.selectedItems.find((el) => el.person.id == studentId);
        const data = {
          amount: -item.balance.toFixed(2),
          date: today(),
          description: "Lehrmittel und Exkursionen",
          event: "Teilrechnung",
          invoiceNumber: "SAP " + today(),
          person: item.person.id,
        };

        await this.apiPost({
          resource: "finance/persontransaction",
          data: data,
          onError: (message) => {
            this.$root.showError(`Fehler: <strong>${message}</strong>`);
          },
          onSuccess: () => {
            const index = this.selectedItems.findIndex(
              (el) => el.person.id == item.person.id
            );
            this.selectedItems.splice(index, 1);
          },
        });
      }
      setTimeout(() => {
        this.fetchData();
        this.saving = false;
        if (this.selectedItems.length == 0) {
          this.invoiceDialog = false;
        }
      }, 1200);
    },
    selectRed() {
      this.selectedItems = this.items.filter((item) => item.balance < -199.99);
    },
    selectSchoolClass(item) {
      this.schoolClass = item;
    },
    selectTeachers() {
      this.schoolClass = {};
    },
    showDetails(id) {
      this.$router.push({
        name: "FinancePersonDetail",
        params: { id: id },
        query: this.$route.query,
      });
    },
    async step4() {
      if (
        await this.$root.confirm({
          message: `Wurden die Daten in Evento eingefügt?`,
          color: "warning",
          icon: "mdi-alert",
        })
      ) {
        this.invoiceStep = 4;
      }
    },
  },
  async created() {
    this.loading = true;
    this.schoolClasses = await this.apiList({
      resource: "schoolclass/schoolclass",
    });

    if (this.$route.query.teachers) {
      this.selectTeachers();
    } else if (this.$route.query.schoolClass) {
      const id = this.$route.query.schoolClass;
      this.schoolClass = this.schoolClasses.find(
        (item) => item.id === parseInt(id)
      );
    } else {
      const id = localStorage.getItem("financeSchoolClass") || "0";
      if (id == "teachers") {
        this.selectTeachers();
      } else {
        this.schoolClass = this.schoolClasses.find(
          (item) => item.id === parseInt(id)
        );
      }
    }

    this.loading = false;
  },
});
</script>
<style>
.code {
  font-family: monospace;
  font-size: small;
}
</style>
